.App {
    text-align: center;
    font: Futura PT;
}

.App-logo {
    height: auto;
    pointer-events: none;
    width: 40%;
    max-width: 170px;
    padding: 0;
    margin: 5px 0 0 0;
}

.App-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1em;
}

main{
}

.caption{
    font-size: 10px;
    color: rgb(170, 170, 170);
    padding: .5em;
}

.button-wrapper{
    display: flex;
    flex-direction: column;
}

.link-button{
    min-width: 150px;
    max-width: 3em;
    padding: 1em;
    line-height: 1.25em;
    margin: auto;

    color: #aa9070;
    background: #f7f7f7;
}

.App-link {
    text-decoration: unset;
    width: fit-content;
    margin: auto;
}

.form{
    padding: 2em;
    text-align: left;
    display: flex;
    max-width: 80%;
    margin: auto;
    flex-flow: column;
    justify-content: left;
}

.form label{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.form p {
    font-size: 12px;
    color: rgb(85, 85, 85);
}

.form input{
    padding: 1em;
    color: rgb(85, 85, 85);
    width: 75%;
}

.form .submitButton{
    border: unset;
    color: white;
    background: #aa9070;
    padding: .75em;
    min-width: 20%;
    max-width: 50%;
    font-size: 1em;
}

.form .submitButton:active {
    background-color: white;
    color: #aa9070;
}

.form .submitButton:hover{
    background-color: #e6e6e6;
    color: #aa9070;
}

.link-button:active{
    background-color: white;
    color: #aa9070;
}

.link-button:hover{
    background: #e6e6e6;
}

.result-wrapper{
    margin-bottom: 2em;
}
