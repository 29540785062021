.row{
    /*background: #f7f7f7;*/
    background: #e6e6e6;
    margin: 0 1em;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    min-height: 150px;
    padding: .25em 0;
    justify-content: center;
    transition: transform .2s; /* Animation */
    border-bottom: 1px solid white;
    border-top: 1px solid white;
}

.row:nth-child(even){
}


.row:hover{
    /*background: #e6e6e6;*/
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

}

.row-image{
height: fit-content;
max-height: 75px;
width: fit-content;
max-width: 35%;
}

.text{
    font-size: 14px;
    color: #aa9070;
}
